import type { ScriptsState } from '../../reducers/types/scripts-state';
import type { Translations } from '../translations';

import initCustomSelectors from '../ui-components/editor/customSelector';
import { analytics } from './analytics/analytics';
import {
  activateJoinButton,
  setTextToBlurBlock,
  unShowBlurBlock,
} from './blurButtons';
import checkVideo from './checkVideo';
import experiments from './experiments';
import fancyBox from './fancy-box';
import platformUtmLabel from './platformUtmLabel';
import removeStore from './removeStore';
import { initStoreDropdown } from './storeDrmDropdown';
import { initUserIdModal } from './user-id-modal';
import fixWidowedText from './widowedText';
import youtube from './youtube';

const setGdpr = async (data: ScriptsState) => {
  // compilerOptions.moduleResolution: "node16" in tsconfig.json only requires *.js path for dynamic import
  // eslint-disable-next-line import/extensions
  const gdpr = await import('./gdpr.js');
  return gdpr.default(data);
};

export default async (data: ScriptsState, translations: Translations) => {
  // TODO make function calls based on dependencies
  await setGdpr(data);
  checkVideo(data, translations);
  platformUtmLabel(data, translations);
  fancyBox();
  youtube(data, translations);
  removeStore(data, translations);
  unShowBlurBlock();
  setTextToBlurBlock(translations);
  activateJoinButton();
  fixWidowedText();
  initCustomSelectors();
  initStoreDropdown();
  initUserIdModal(data);
  analytics(data);
  experiments(data);
};
