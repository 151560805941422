/* eslint-disable import/no-dynamic-require */
import {
  allowedLanguages,
  FiveSymbolLocale,
  fiveSymbolsMap,
  TwoSymbolLocale,
} from '@site-builder/common/src/types/locale';
import Cookie from 'js-cookie';
import { addLocaleData } from 'react-intl';

import EmptySpan from './EmptySpan';
import { twoSymbolsMapForPublisher } from './locales-map';

export const defaultLocale = 'en-US';
export const getDefaultMessages = (): Record<string, string> =>
  require('../translations/en-US');

// этот импорт должен быть без переменных
export const defaultLocaleWithoutRegionCode = 'en';
export const acceptLocales = Object.keys(twoSymbolsMapForPublisher);

const cookieLocale = Cookie.get('pa-locale') as TwoSymbolLocale;

export const detectUserLocale = (): FiveSymbolLocale =>
  twoSymbolsMapForPublisher[cookieLocale]
    ? twoSymbolsMapForPublisher[cookieLocale]
    : defaultLocale;

export const intlAddLocaleData = async () => {
  type TwoSymbolLocaleWithZh = TwoSymbolLocale | 'zh';
  const twoSymbolLocale =
    cookieLocale && twoSymbolsMapForPublisher[cookieLocale]
      ? cookieLocale
      : (defaultLocaleWithoutRegionCode as TwoSymbolLocaleWithZh);

  const loadLocaleData = (data) => addLocaleData([data.default]);
  switch (twoSymbolLocale) {
    case 'en':
      await import(`react-intl/locale-data/en.js`).then(loadLocaleData);
      break;
    case 'ru':
      await import(`react-intl/locale-data/ru.js`).then(loadLocaleData);
      break;
    case 'ko':
      await import(`react-intl/locale-data/ko.js`).then(loadLocaleData);
      break;
    case 'zh':
      await import(`react-intl/locale-data/zh.js`).then(loadLocaleData);
      break;
    case 'de':
      await import(`react-intl/locale-data/de.js`).then(loadLocaleData);
      break;
    case 'ja':
      await import(`react-intl/locale-data/ja.js`).then(loadLocaleData);
      break;
    default:
      break;
  }
};

export const getTranslationsData = (locale) => {
  const fileName =
    locale || twoSymbolsMapForPublisher[defaultLocaleWithoutRegionCode];
  return import(`../translations/${fileName}.js`);
};

export const setHtmlLangFromCookie = () => {
  const twoSymbolLocale =
    cookieLocale && twoSymbolsMapForPublisher[cookieLocale]
      ? cookieLocale
      : defaultLocaleWithoutRegionCode;
  const htmlElement = document.querySelector('html');
  htmlElement?.setAttribute('lang', twoSymbolLocale);
};

let apiTranslations;
export const importApiTranslations = async () => {
  const promises = allowedLanguages.map((locale) =>
    import(`../../../server/src/translations/${locale}.js`).catch(() => null)
  );
  const modules = await Promise.all(promises);
  apiTranslations = allowedLanguages.reduce(
    (translations, languageKey, index) => ({
      ...translations,
      [languageKey]: modules[+index]?.default ?? null,
    }),
    {}
  );
};

const insertValues = (sourceString, values) => {
  let labelText = sourceString;
  Object.keys(values).forEach((key) => {
    const regexp = new RegExp(`{${key}}`, 'g');
    labelText = labelText.replace(regexp, values[`${key}`]);
  });
  return labelText;
};

const dataAttribute = 'data-translation-data-json';
const camelCaseDataAttribute = 'translationDataJson';

export const setLabelsToSSRLanding = (translations: Record<string, string>) => {
  const elements = document.querySelectorAll(
    `[${dataAttribute}]`
  ) as NodeListOf<HTMLElement>;

  elements.forEach((element) => {
    const jsonData = element.dataset[camelCaseDataAttribute] as string;
    const { label, values } = JSON.parse(jsonData);
    const labelText = insertValues(translations[`${label}`], values);
    element.replaceWith(labelText);
  });
};

const getPreviewLabels =
  (label) =>
  (locale, values = {}) => {
    const isServerSideRender =
      !apiTranslations && typeof window === 'undefined';
    if (isServerSideRender) {
      return EmptySpan({ [dataAttribute]: JSON.stringify({ label, values }) });
    }

    const labelText: string = apiTranslations
      ? apiTranslations[`${locale}`]?.[`${label}`] ||
        apiTranslations[`${defaultLocale}`][`${label}`]
      : window.__TRANSLATIONS__[`${label}`];

    return insertValues(labelText, values);
  };

export const convertFiveSymbolsToTwoSymbolsLocale = (
  language: FiveSymbolLocale
) => {
  try {
    if (
      language === null ||
      !language ||
      Array.isArray(language) ||
      language === Object(language)
    ) {
      return defaultLocaleWithoutRegionCode;
    }

    const localeAr = language.split('-');

    if (localeAr.length > 1) {
      return fiveSymbolsMap[language];
    }
    return defaultLocaleWithoutRegionCode;
  } catch (e) {
    return defaultLocaleWithoutRegionCode;
  }
};

export const getReqSysRecommendedText = getPreviewLabels(
  'default-data.values.requirements.title.recommended'
);
export const getReqSysMinimumText = getPreviewLabels(
  'default-data.values.requirements.title.minimum'
);

export const getInstructionStepText = getPreviewLabels(
  'document.pages.auth.instruction.steps.description'
);

export const getNewsBlurredJoinBtnText = getPreviewLabels(
  'client.news.blurred.button.join'
);
export const getNewsBlurredDescriptionText = getPreviewLabels(
  'client.news.blurred.description'
);

export const getPrivacySettingsText = getPreviewLabels(
  'client.privacy-settings.open-button'
);
export const getUSAPrivacySettingsText = getPreviewLabels(
  'client.usa-privacy-settings.open-button'
);

export const getXsollaLogoPoweredText = getPreviewLabels(
  'client.footer.xsollalogo.powered'
);
export const getXsollaLogoSbText = getPreviewLabels(
  'client.footer.xsollalogo.sb'
);
export const getSubscribersListButtonText = getPreviewLabels(
  'client.footer.button.list_of_subscribers'
);

export const getInputPlaceholderTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-placeholder'
);
export const getButtonGoBackTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.go-back'
);
export const getSocialNameTextForUserIdModal = (socialName: string) =>
  getPreviewLabels(`client.user-id-modal.socials.${socialName}`);

export const getContunueWithSociaTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.continue-with'
);

export const getInputPasteButtonTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-paste-button'
);
export const getInputIncorrectTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-incorrect-id'
);
export const getInputIncorrectCopyPasteTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-incorrect-id-copy-and-paste'
);
export const getContinueButtonTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.continue-button'
);

export const getCommonSubscriptionButtonText = getPreviewLabels(
  'subscription.button.text'
);
export const getLifetimeSubscriptionButtonText = getPreviewLabels(
  'client.subscription.button.lifetime_text'
);

export const getShowMoreText = getPreviewLabels('client.store.item.show_more');

export const getStoreCheckoutText = getPreviewLabels(
  'client.buy_button.checkout'
);

export const getStoreBuyButtonText = getPreviewLabels(
  'blocks.store.buy_button.text'
);
export const getAddToCartText = getPreviewLabels(
  'client.store.cart.add_button'
);
export const getStoreMoreThan10GameKeysErrorText = getPreviewLabels(
  'client.store.error.gt10_gk'
);
export const getStoreNoGameKeysErrorText = getPreviewLabels(
  'client.store.error.no_keys'
);
export const getStoreСheckoutText = getPreviewLabels(
  'client.buy_button.checkout'
);
export const getPreOrderText = getPreviewLabels('client.buy_button.pre-order');

export const getUserAccountLoginText = getPreviewLabels(
  'client.header.login.button'
);
export const getUserAccountLogoutText = getPreviewLabels(
  'client.header.logout.button'
);

export const getHeaderStoreButtonText = getPreviewLabels(
  'client.store.cart.header_button'
);

export const getSubscriptionsPacksButtonRenewNow = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.renew.now'
);
export const getSubscriptionsPacksButtonManagePlan = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.manage.plan'
);
export const getSubscriptionsPacksSelectYourPlan = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.select.plan'
);
export const getSubscriptionsPacksNextRenewal = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.next.renewal'
);
export const getSubscriptionsPacksButtonConfigureSubscription =
  getPreviewLabels(
    'editor.subscriptions-packs.manage.subscriptions.configure.subscription'
  );

export const getSubscriptionsTranslation = ({
  translationName,
  locale,
  options,
}) => getPreviewLabels(translationName)(locale, options);

export const getBundleModalGroupText = getPreviewLabels(
  'client.bundle_modal.group_items'
);
